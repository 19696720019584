import vuexHelpers from "../helpers/vuex.helpers";
import notificationModule from "./notification.module";
import userModule from "./user.module";
import uploadNotificationFormModule from "./upload.notification.form.module";
import NotificationService from "../services/notification.service";

const getInitialState = function () {
  return {
    search: null,
    tableLoading: false,
    tableParams: {},
    removeDialog: false,
    usersDialog: false,
    dialogSelectedUsers: []
  };
};

const initialState = getInitialState();
const getters = {};

const actions = {
  async loadTableData({ commit, dispatch, state }) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Caricamento dati tabella notifications",
      {
        root: true,
      }
    );
    state.tableLoading = true;
    try {
      await dispatch("notifications/table", {
        tableParams: state.tableParams,
        search: state.search
      });
    } catch (error) {
      await dispatch(
        "app/addAlert",
        {
          text: error,
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
    state.tableLoading = false;
  },
  async editUsers({ commit, dispatch }, data) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Salvataggio utenti",
      {
        root: true,
      }
    );

    try {
      const res = await NotificationService.editUsers(data);

      if (res.isError)
        throw "Errore nel salvataggio degli utenti";

      await dispatch("loadTableData");
    } catch (error) {
      console.error(error)
      await dispatch(
        "app/addAlert",
        {
          text: "Errore nel salvataggio degli utenti",
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
  },
  async delete({ commit, dispatch }, id) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Cancellazione notifica",
      {
        root: true,
      }
    );

    try {
      const res = await NotificationService.delete(id);
      if (res.isError)
        throw "Errore nella cancellazione della notifica";

      commit('notifications/setCurrentId', null);
      await dispatch("loadTableData");
    } catch (error) {
      await dispatch(
        "app/addAlert",
        {
          text: error,
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
  },
}

const mutations = {};

const filesViewModule = {
  namespaced: true,
  state: initialState,
  modules: {
    notifications: notificationModule,
    uploadNotificationForm: uploadNotificationFormModule,
    users: userModule
  },
  actions: { ...actions },
  getters: {
    ...getters,
  },
  mutations: {
    ...vuexHelpers.mutations(getInitialState),
    ...vuexHelpers.tableMutations(getInitialState),
    ...mutations,
  },
};

export default filesViewModule;
