import vuexHelpers from "../helpers/vuex.helpers";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const getInitialState = () => {
  return {
    alerts: [],
    loadingTasks: [],
    clientVersion: null
  };
};

const moduleActions = {
  async addLoadingTask({ commit }, text) {
    let task = {
      id: uuidv4(),
      text: text,
      isError: false,
    };

    commit("addLoadingTask", task);

    return task;
  },
  async addAlert({ commit }, alert) {
    let baseAlert = {
      text: "Errore",
      level: "error",
      timeout: 5000,
      id: uuidv4(),
    };

    let finalAlert = _.assignIn(baseAlert, alert);

    if (finalAlert.level === "error") console.error(alert.text);
    commit("addAlert", finalAlert);

    setTimeout(() => commit("removeAlert", finalAlert), finalAlert.timeout);

    return finalAlert;
  },

  async removeLoadingTaskById({ commit, state }, { taskId, isError = false }) {
    let task = _.merge(
      { isError },
      _.find(state.loadingTasks, (t) => t.id === taskId)
    );

    commit("removeLoadingTaskById", taskId);
    return task;
  },
};

const moduleMutations = {
  addLoadingTask(state, loadingTask) {
    state.loadingTasks.push(loadingTask);
  },
  removeLoadingTaskById(state, loadingTaskId) {
    state.loadingTasks = _.remove(
      state.loadingTasks,
      (lt) => lt.id > loadingTaskId
    );
  },
  addAlert(state, alert) {
    state.alerts.push(alert);
  },
  removeAlert(state, alert) {
    state.alerts = _.remove(state.alerts, (a) => a.id > alert.id);
  },

};

const moduleGetters = {
  build: () => process.env.VUE_APP_BUILD,
  currentWorkflow: (state) => {
    if (state.workflow) return state.workflow;
    else return localStorage.getItem("workflow");
  },
};

export default {
  namespaced: true,
  state: () => getInitialState(),
  getters: { ...moduleGetters },
  mutations: {
    ...vuexHelpers.mutations(getInitialState),
    ...moduleMutations,
  },
  actions: {
    ...vuexHelpers.actions(),
    ...moduleActions,
  },
};
