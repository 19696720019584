import axios from 'axios';
import store from '../store/index'
import { router } from '../router';

export default function setup() {
    axios.interceptors.request.use(function (config) {

        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.token)
            config.headers.Authorization = `Bearer ${user.token}`;

        return config;
    }, function (err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (response) {
        const clientVersion = response.headers['x-client-version'];
        if (store.state.app.clientVersion === null)
            store.commit('app/setClientVersion', clientVersion)
        else if (store.state.app.clientVersion !== clientVersion)
            location.reload();

        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async function (error) {
        if (error.response.status === 401) {
            await store.dispatch('auth/logout')
            router.push('/login')
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });
}