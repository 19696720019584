import { normalize, schema } from "normalizr";
import UserService from "../services/user.service";
import roleModule from "./role.module";

// import { normalize, schema, denormalize } from "normalizr";
import vuexHelpers from "../helpers/vuex.helpers";
const userEntity = new schema.Entity("users");
const getInitialState = function () {
    return {
        entities: {},
        ids: [],
        currentId: null,
        totalItems: 0,

        email: null,
        fullName: null,
        password: null,
        selectedRoles: [],
    };
};
const initialState = getInitialState();

const getters = {
    find: (state) => (id) => {
        return state.entities.users[id];
    },
    current: (state, getters) => {
        return state.currentId !== null ? getters.find(state.currentId) : null;
    },
    list: (state, getters) => {
        return state.ids.map((id) => getters.find(id));
    },
    editMode: (state) => state.currentId !== null,
};

const mutations = {
    setNormalizedData(state, normalizedData) {
        state.entities = normalizedData.entities;
        state.ids = normalizedData.result;
    },
};

const actions = {
    async updateList({ commit }, list) {
        try {
            commit("setNormalizedData", normalize(list, [userEntity]));
        } catch (error) {
            await dispatch(
                "app/addAlert",
                {
                    text: error,
                },
                { root: true }
            );
        }
    },
    async search({ commit, dispatch }, q) {
        let task = await dispatch("app/addLoadingTask", "Caricamento utenti", {
            root: true,
        });

        try {
            let res = await UserService.search(q);
            if (res.isError)
                throw "Errore nella ricerca dell'utente";
            commit("setNormalizedData", normalize(res.data, [userEntity]));
        } catch (error) {
            await dispatch(
                "app/addAlert",
                {
                    text: error,
                },
                { root: true }
            );
        }
        commit("app/removeLoadingTaskById", task.id, { root: true });
    },
    async table({ commit, dispatch }, data) {
        let task = await dispatch("app/addLoadingTask", "Caricamento utenti", {
            root: true,
        });

        try {
            let res = await UserService.table(data);
            if (res.isError)
                throw "Errore nel caricamento degli utenti";
            commit('setTotalItems', res.data.totalItems)
            commit("setNormalizedData", normalize(res.data.items, [userEntity]));
        } catch (error) {
            await dispatch(
                "app/addAlert",
                {
                    text: error,
                },
                { root: true }
            );
        }
        commit("app/removeLoadingTaskById", task.id, { root: true });
    },
}

const userModule = {
    namespaced: true,
    state: initialState,
    modules: { roles: roleModule },
    getters: { ...vuexHelpers.getters(initialState), ...getters },
    actions: {
        ...actions
    },
    mutations: {
        ...vuexHelpers.mutations(getInitialState),
        // ...vuexHelpers.modelMutations(formCodiceModel, "codici", {
        //   setterEntityName: "codice",
        // }),
        ...mutations,
    },
};

export default userModule;
