import axios from "axios";

// const ENDPOINT_BASE_URL = `${process.env.VUE_APP_API_URL}servizi-rete/`;
const ENDPOINT_BASE_URL = `${process.env.VUE_APP_API_URL}roles/`;

class RoleService {
    async search(options) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}`, options);
        return res.data;
    }
}
export default new RoleService();
