import _ from "lodash";
import { denormalize } from "normalizr";
import modelsHelpers from "./models.helpers";

export default {
  getters: (initialState) => {
    return Object.keys(initialState).reduce((getters, key) => {
      getters[key] = (state) => state[key];
      return getters;
    }, {});
  },

  listGetters: (modelEntity) => {
    return {
      find: (state) => (id) => {
        return state.entities?.[modelEntity.key][id];
      },
      current: (state, getters) => {
        return state.currentId !== null ? getters.find(state.currentId) : null;
      },
      list: (state, getters) => {
        return state.ids.map((id) => getters.find(id));
      },
      isNew: (state) => modelsHelpers.isIdNew(state.currentId),
      isNewOrNull: (state) => modelsHelpers.isIdNewOrNull(state.currentId),
      denormalize: (state) =>
        modelsHelpers.idCleaner(
          denormalize(
            state.ids,
            Array.isArray(state.ids) ? [modelEntity] : modelEntity,
            state.entities
          )
        ),
    };
  },
  mutations: (getInitialStateFunction) => {
    var mutationsObj = Object.keys(getInitialStateFunction()).reduce(
      (mutations, key) => {
        let firstLetterUpKey = key.replace(/^./, key[0].toUpperCase());
        mutations[`set${firstLetterUpKey}`] = (state, value) =>
          (state[key] = value);
        return mutations;
      },
      {}
    );
    mutationsObj.resetState = (state) =>
      Object.assign(state, getInitialStateFunction());
    return mutationsObj;
  },
  tableMutations: (getInitialStateFunction) => {
    return {
      setFiltersData(state, value) {
        if (typeof value === "undefined" || value === null)
          value = getInitialStateFunction();

        Object.assign(state, value);
      },
    };
  },
  crudMudations: {
    setNormalizedData(state, normalizedData) {
      state.entities = normalizedData.entities;
      if (Array.isArray(normalizedData.result))
        state.ids = normalizedData.result;
      else state.currentId = normalizedData.result;
    },
    setCustomNormalizedData(
      state,
      { normalizedData, entitiesPropertyName, idsPropertyName }
    ) {
      state[entitiesPropertyName || "entities"] = normalizedData.entities;
      state[idsPropertyName || "ids"] = normalizedData.result;
    },
    addNormalizedData(state, normalizedData) {
      state.entities = _.merge(state.entities, normalizedData.entities);

      if (Array.isArray(normalizedData.result))
        state.ids = [...state.ids, ...normalizedData.result];
      else if (state.ids.indexOf(normalizedData.result) < 0)
        state.ids.push(normalizedData.result);
    },
    addCustomNormalizedData(
      state,
      { normalizedData, entitiesPropertyName, idsPropertyName }
    ) {
      state[entitiesPropertyName || "entities"] = _.merge(
        state[entitiesPropertyName || "entities"],
        normalizedData.entities
      );

      if (Array.isArray(normalizedData.result))
        state[idsPropertyName || "ids"] = [
          ...state[idsPropertyName || "ids"],
          ...normalizedData.result,
        ];
      else if (
        state[idsPropertyName || "ids"].indexOf(normalizedData.result) < 0
      )
        state[idsPropertyName || "ids"].push(normalizedData.result);
    },
    deleteCustomItem(state, { itemId, idsPropertyName }) {
      state[idsPropertyName || "ids"] = _.without(state.ids, itemId);
    },
    resetCustomItems(state, { entitiesPropertyName, idsPropertyName }) {
      state[idsPropertyName || "ids"] = [];
      state[entitiesPropertyName || "entities"] = {};
    },
    deleteItem(state, itemId) {
      state.ids = _.without(state.ids, itemId);
    },
    resetItems(state) {
      state.ids = [];
      state.entities = {};
    },
  },

  modelMutations: (modelDefinition, stateKey, options) => {
    if (!options) options = {};

    if (!options.setterEntityName) options.setterEntityName = stateKey;
    return Object.keys(modelDefinition).reduce((mutations, key) => {
      let firstLetterUpKey = key.replace(/^./, key[0].toUpperCase());
      let firstLetterUpStateKey = options.setterEntityName.replace(
        /^./,
        options.setterEntityName[0].toUpperCase()
      );
      mutations[`set${firstLetterUpStateKey}${firstLetterUpKey}`] = (
        state,
        { entityId, value }
      ) => {
        state.entities[stateKey][entityId][key] = value;
      };
      return mutations;
    }, {});
  },

  actions: (subModules = {}) => {
    return {
      async resetAllStates({ dispatch, commit }) {
        commit("resetState");
        for (const subModule in subModules) {
          await dispatch(`${subModule}/resetAllStates`);
        }
      },
    };
  },
};
