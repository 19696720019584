export default {
  downloadFile(file, fileName = "file.pdf") {
    var fileURL = window.URL.createObjectURL(new Blob([file]));
    var fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    URL.revokeObjectURL(fileLink.href);
  },
};
