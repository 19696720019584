import axios from 'axios';

const ENDPOINT_BASE_URL = `/api/logs`;

class LogService {
    async table(data) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/table`, data);
        return res.data;
    }

}

export default new LogService();