import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({ commit, dispatch }, loginData) {
            let result = null;
            let task = await dispatch(
                "app/addLoadingTask",
                "Login in corso",
                {
                    root: true,
                }
            );

            try {
                let res = await AuthService.login(loginData);
                if (res.isError) {
                    result = res.errors.length > 0 ? res.errors[0] : "Errore"
                } else {
                    commit('loginSuccess', res.data);
                    result = res.data;
                }

            } catch (error) {
                await dispatch(
                    "app/addAlert",
                    {
                        text: error,
                    },
                    { root: true }
                );
                commit('loginFailure');
                result = "Si è verificato un errore";
            }
            await dispatch(
                "app/removeLoadingTaskById",
                { taskId: task.id, isError: task.isError },
                { root: true }
            );
            return result;
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};