import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
Vue.config.productionTip = false

import { router } from './router';
import store from './store';

// import VeeValidate from 'vee-validate';
import * as VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

import it from 'vee-validate/dist/locale/it.json';
VeeValidate.localize({
  it,
});
VeeValidate.localize('it');

import interceptorsSetup from './helpers/interceptors'
interceptorsSetup();

new Vue({
  router, store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
