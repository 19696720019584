import fileHelpers from "../helpers/file.helpers";
import vuexHelpers from "../helpers/vuex.helpers";
import FilesService from '../services/files.service'
import userModule from "./user.module";
import fileModule from "./file.module";
import uploadFileFormModule from "./upload.file.form.module";

const getInitialState = function () {
  return {
    tableLoading: false,
    tableParams: {},
    user: null,
    dialogSelectedUsers: [],
    addUserDialog: false,
    removeFileDialog: false,
    searchFilter: null,
  };
};

const initialState = getInitialState();
const getters = {};

const actions = {
  async loadTableData({ commit, dispatch, state }) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Caricamento dati tabella uploadedFile",
      {
        root: true,
      }
    );
    state.tableLoading = true;
    try {
      await dispatch("files/search", {
        tableParams: state.tableParams,
        ownerId: state.user?.id || null,
        search: state.searchFilter,
      });
    } catch (error) {
      console.error(error);
      await dispatch(
        "app/addAlert",
        {
          text: "Errore nel caricamento dei file",
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
    state.tableLoading = false;
  },
  async saveShareWith({ commit, dispatch }, data) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Salvataggio file",
      {
        root: true,
      }
    );

    try {
      const res = await FilesService.saveShareWith(data);

      if (res.isError)
        throw "Errore nel salvataggio del file";

      await dispatch("loadTableData");
    } catch (error) {
      console.error(error);
      await dispatch(
        "app/addAlert",
        {
          text: "Errore nel salvataggio del file",
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
  },
  async deleteFile({ commit, dispatch }, fileId) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Cancellazione file",
      {
        root: true,
      }
    );

    try {
      const res = await FilesService.delete(fileId);

      if (res.isError)
        throw "Errore nella cancellazione del file";

      await dispatch("loadTableData");
    } catch (error) {
      await dispatch(
        "app/addAlert",
        {
          text: error,
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
  },
  async downloadFile({ commit, dispatch }, { id, filename }) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Download file",
      {
        root: true,
      }
    );

    try {
      const file = await FilesService.download(id);
      fileHelpers.downloadFile(file, filename);
    } catch (error) {
      console.error(error);

      await dispatch(
        "app/addAlert",
        {
          text: "Errore nel download del file",
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
  },
};

const mutations = {};

const filesViewModule = {
  namespaced: true,
  state: initialState,
  modules: {
    users: userModule,
    files: fileModule,
    uploadFileForm: uploadFileFormModule,
  },
  actions: { ...actions },
  getters: {
    ...getters,
  },
  mutations: {
    ...vuexHelpers.mutations(getInitialState),
    ...vuexHelpers.tableMutations(getInitialState),
    ...mutations,
  },
};

export default filesViewModule;
