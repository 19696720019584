import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import app from './app.module';
import filesView from './files.view.module'
import usersView from './users.view.module'
import logsView from './logs.view.module'
import notificationsView from './notifications.view.module'

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    modules: {
        auth,
        app,
        filesView,
        usersView,
        logsView,
        notificationsView
    }
});