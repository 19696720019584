export default {
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isUserAdmin() {
            console.log('this.currentUser?.roleId', this.currentUser?.roleId)
            return this.currentUser?.roleId === 1
        },
    }
}