import { v4 as uuidv4 } from "uuid";

function id() {
  return uuidv4();
}

const isNewId = function(id) {
  if (typeof id === "undefined" || id === null) return false;
  return typeof id === "string" ? id.startsWith("new") : false;
};
const idCleaner = function(obj) {
  for (let propertyName in obj) {
    if (propertyName.toLowerCase() == "id" && isNewId(obj[propertyName]))
      obj[propertyName] = null;

    if (typeof obj[propertyName] === "object" && obj[propertyName] !== null)
      idCleaner(obj[propertyName]);
    else if (Array.isArray(obj[propertyName]))
      for (let arrI = 0; arrI < obj[propertyName], length; arrI++)
        idCleaner(obj[propertyName][arrI]);
  }

  return obj;
};

export default {
  id,
  newId: () => `new${id()}`,
  isNewId,
  idCleaner,
};
