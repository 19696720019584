import vuexHelpers from "../helpers/vuex.helpers";
import logModule from "./log.module";

const getInitialState = function () {
  return {
    email: null,
    file: null,
    dateRange: null,
    tableLoading: false,
    tableParams: {},
  };
};

const initialState = getInitialState();
const getters = {};

const actions = {
  async loadTableData({ commit, dispatch, state }) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Caricamento dati tabella logs",
      {
        root: true,
      }
    );
    state.tableLoading = true;
    try {
      await dispatch("logs/table", {
        tableParams: state.tableParams,
        email: state.email,
        file: state.file,
        dateRange: state.dateRange
      });
    } catch (error) {
      await dispatch(
        "app/addAlert",
        {
          text: error,
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
    state.tableLoading = false;
  },
};

const mutations = {};

const filesViewModule = {
  namespaced: true,
  state: initialState,
  modules: {
    logs: logModule,
  },
  actions: { ...actions },
  getters: {
    ...getters,
  },
  mutations: {
    ...vuexHelpers.mutations(getInitialState),
    ...vuexHelpers.tableMutations(getInitialState),
    ...mutations,
  },
};

export default filesViewModule;
