<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app v-if="showDrawer">
      <v-list dense>
        <v-list-item
          v-for="navItem in visibleLinks"
          :key="navItem.link"
          link
          :to="navItem.link"
        >
          <v-list-item-icon>
            <v-icon v-text="navItem.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ navItem.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark v-if="showAppBar">
      <v-app-bar-nav-icon v-if="showDrawer" @click.stop="drawer = !drawer" />
      <v-toolbar-title>G&O Filemanager</v-toolbar-title>
      <v-spacer></v-spacer>
      {{ this.currentUser.email }}
      <v-btn v-if="currentUser !== null" icon @click="logOut">
        <v-icon>fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <div v-if="loadingTasks.length > 0" class="applicationLoadingContainer">
      <p><v-icon class="fa-spin">fa-spinner</v-icon> Caricamento in corso</p>
    </div>
    <v-main>
      <div id="alertsContainer">
        <v-alert
          v-for="alert in alerts"
          :key="alert.id"
          border="top"
          :type="alertType(alert.level)"
        >
          {{ alert.text }}
        </v-alert>
      </div>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import authMixin from "./mixins/auth.mixin";
import AuthService from "./services/auth.service";
export default {
  name: "App",
  mixins: [authMixin],
  computed: {
    alerts() {
      return this.$store.state.app.alerts;
    },
    loadingTasks() {
      return this.$store.state.app.loadingTasks;
    },
    showDrawer() {
      return this.showAppBar && this.visibleLinks.length > 1;
    },
    showAppBar() {
      return (
        this.currentUser &&
        this.$route.name !== null &&
        this.$route.name !== "Login"
      );
    },
    visibleLinks() {
      return _.filter(
        this.navigationLinks,
        (l) => !l.adminOnly || this.isUserAdmin
      );
    },
  },
  async mounted() {
    AuthService.clientVersion();
  },
  methods: {
    alertType(level) {
      switch (level) {
        case "success":
          return "success";
        default:
          return "error";
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  data() {
    return {
      drawer: false,
      navigationLinks: [
        {
          link: "/files",
          icon: "fa-file",
          text: "Files",
        },
        {
          link: "/users",
          icon: "fa-user",
          text: "Utenti",
          adminOnly: true,
        },
        {
          link: "/logs",
          icon: "fa-calendar-alt",
          text: "Logs",
          adminOnly: true,
        },
        {
          link: "/notifications",
          icon: "fa-bell",
          text: "Notifiche",
          adminOnly: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#alertsContainer {
  position: fixed;
  top: 64px;
  right: 20px;
  max-width: 300px;
  z-index: 9999;
}

.applicationLoadingContainer {
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  padding-top: 50px;
  p {
    display: inline-block;
    background: #fff;
    padding: 20px;
  }
}
.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>