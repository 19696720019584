import axios from 'axios';

const ENDPOINT_BASE_URL = `/api/users`;

class UserService {
    async table(data) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/table`, data);
        return res.data;
    }
    async save(data) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/save`, data);
        return res.data;
    }
    async search(q) {
        const res = await axios.get(ENDPOINT_BASE_URL + '?q=' + q);
        return res.data;
    }
    async generatePassword() {
        const res = await axios.get(ENDPOINT_BASE_URL + '/generatePassword');
        return res.data;
    }
}

export default new UserService();