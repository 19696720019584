import { normalize, schema } from "normalizr";
import FilesService from "../services/files.service";
import vuexHelpers from "../helpers/vuex.helpers";

const UploadedFileEntity = new schema.Entity("uploadedFiles");


const getInitialState = function () {
  return {
    entities: {},
    ids: [],
    currentId: null,
    totalItems: 0,
  };
};
const initialState = getInitialState();


const getters = {};

const actions = {
  async resetItems({ commit }) {
    commit('setTotalItems', 0)
    let normalizedData = normalize([], [UploadedFileEntity]);
    commit("setNormalizedData", normalizedData);
  },
  async search({ commit, dispatch, state }, options) {
    let task = await dispatch(
      "app/addLoadingTask",
      "Caricamento uploadedFile",
      {
        root: true,
      }
    );

    try {
      let res = await FilesService.filesTable(options);
      if (res.isError)
        throw "Errore nella ricerca del file";

      commit('setTotalItems', res.data.totalItems)
      let normalizedData = normalize(res.data.items, [UploadedFileEntity]);
      commit("setNormalizedData", normalizedData);
    } catch (error) {
      await dispatch(
        "app/addAlert",
        {
          text: error,
        },
        { root: true }
      );
    }
    commit("app/removeLoadingTaskById", task.id, { root: true });
  },
};
const mutations = {
  resetItems(state) {

  }
};
const uploadedFileModule = {
  namespaced: true,
  state: initialState,

  getters: {
    ...vuexHelpers.getters(initialState),
    ...vuexHelpers.listGetters(UploadedFileEntity),
    ...getters,
  },
  actions: { ...actions },
  mutations: {
    ...vuexHelpers.mutations(getInitialState),
    ...vuexHelpers.crudMudations,
    ...mutations,
  },
};

export default uploadedFileModule;
