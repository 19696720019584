import { normalize, schema } from "normalizr";
import RoleService from "../services/role.service";

// import { normalize, schema, denormalize } from "normalizr";
import vuexHelpers from "../helpers/vuex.helpers";
const roleEntity = new schema.Entity("roles");

const getInitialState = function () {
    return {
        entities: {},
        ids: [],
        currentId: null,
    };
};

const initialState = getInitialState();

const getters = {
    find: (state) => (id) => {
        return state.entities.roles[id];
    },
    current: (state, getters) => {
        return state.currentId !== null ? getters.find(state.currentId) : null;
    },
    list: (state, getters) => {
        return state.ids.map((id) => getters.find(id));
    },

    editMode: (state) => state.currentId !== null,
};

const mutations = {
    setNormalizedData(state, normalizedData) {
        state.entities = normalizedData.entities;
        state.ids = normalizedData.result;
    },
};

const roleModule = {
    namespaced: true,
    state: initialState,
    getters: { ...vuexHelpers.getters(initialState), ...getters },
    actions: {
        async search({ commit, dispatch }, options) {
            let task = await dispatch("app/addLoadingTask", "Caricamento ruoli", {
                root: true,
            });

            try {
                let res = await RoleService.search(options);

                if (res.isError)
                    throw "Errore nel caricamento dei ruoli";

                let normalizedData = normalize(res.content, [roleEntity]);

                commit("setNormalizedData", normalizedData);
            } catch (error) {
                await dispatch(
                    "app/addAlert",
                    {
                        text: error,
                    },
                    { root: true }
                );
            }
            commit("app/removeLoadingTaskById", task.id, { root: true });
        },
    },
    mutations: {
        ...vuexHelpers.mutations(getInitialState),
        // ...vuexHelpers.modelMutations(formCodiceModel, "codici", {
        //   setterEntityName: "codice",
        // }),
        ...mutations,
    },
};
export default roleModule;
