import { schema } from "normalizr";
import NotificationService from "../services/notification.service";
import vuexHelpers from "../helpers/vuex.helpers";
import userModule from "./user.module";

const notificationEntity = new schema.Entity("notifications");

const getInitialState = function () {
    return {
        selectedUsers: [],
        files: null,
        filenameCheck: null,
    };
};
const initialState = getInitialState();



const getters = {};

const actions = {
    async save({ commit, dispatch, state }) {
        let task = await dispatch(
            "app/addLoadingTask",
            "Salvataggio notifica",
            {
                root: true,
            }
        );

        try {
            const res = await NotificationService.save({
                filenameCheck: state.filenameCheck,
                userIds: state.selectedUsers,
            });
            if (res.isError)
                throw "Errore nel salvataggio della notifica";

            commit("setFormData", null);
            await dispatch("notificationsView/loadTableData", null, { root: true });
        } catch (error) {
            await dispatch(
                "app/addAlert",
                {
                    text: error,
                },
                { root: true }
            );
        }
        commit("app/removeLoadingTaskById", task.id, { root: true });
    },
};
const mutations = {
    setFormData(state, item) {
        state.currentId = item?.id || null;
        state.datanotification = item?.datanotification || null;
        state.filenameCheck = item?.filenameCheck || null;
        state.selectedUsers = item?.users || [];
        state.files = item?.files || [];
    },
};
const uploadFileFormModule = {
    namespaced: true,
    state: initialState,
    modules: {
        users: userModule,
    },
    getters: {
        ...vuexHelpers.getters(initialState),
        ...vuexHelpers.listGetters(notificationEntity),
        ...getters,
    },
    actions: { ...actions },
    mutations: {
        ...vuexHelpers.mutations(getInitialState),
        ...vuexHelpers.crudMudations,
        ...mutations,
    },
};

export default uploadFileFormModule;
