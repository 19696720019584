import axios from 'axios';

const ENDPOINT_BASE_URL = `/api/users`;

class AuthService {
    async login(loginData) {
        const response = await axios
            .post(ENDPOINT_BASE_URL + '/authenticate', loginData);

        if (response.data.data?.token) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
        }

        return response.data;
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(ENDPOINT_BASE_URL + '/signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }

    clientVersion() {
        return axios
            .get(ENDPOINT_BASE_URL + '/clientVersion');
    }
}

export default new AuthService();