import { normalize, schema } from "normalizr";
import FilesService from "../services/files.service";
import vuexHelpers from "../helpers/vuex.helpers";
import modelsHelpers from "../helpers/models.helpers";
import dateHelpers from "../helpers/dates.helpers";
import userModule from "./user.module";

const uploadedFileEntity = new schema.Entity("uploadedFiles");

const getInitialState = function () {
    return {
        visibleTo: [],
        files: null,
        titolo: null,
        progressValue: null,
    };
};
const initialState = getInitialState();



const getters = {};

const actions = {
    async save({ commit, dispatch, state }) {
        let task = await dispatch(
            "app/addLoadingTask",
            "Salvataggio file",
            {
                root: true,
            }
        );
        commit("setProgressValue", 0);

        try {
            let formData = new FormData();
            formData.append('data', JSON.stringify({
                titolo: state.titolo,
                visibleTo: state.visibleTo,
            }))
            for (const i of Object.keys(state.files)) {
                formData.append('files', state.files[i])
            }

            const res = await FilesService.save(formData, commit, 'updateUploadProgress');
            if (res.isError)
                throw "Errore nel salvataggio del file";

            commit("setFormData", null);
            await dispatch("filesView/loadTableData", null, { root: true });
        } catch (error) {
            console.error(error);
            await dispatch(
                "app/addAlert",
                {
                    text: 'Errore nel salvataggio del file',
                },
                { root: true }
            );
        }
        commit("setProgressValue", null);
        commit("app/removeLoadingTaskById", task.id, { root: true });
    },
};
const mutations = {
    setFormData(state, item) {
        state.currentId = item?.id || null;
        state.datauploadedFile = item?.datauploadedFile || null;
        state.titolo = item?.titolo || null;
        state.visibleTo = item?.visibleTo || [];
        state.files = item?.files || [];
    },
    updateUploadProgress(state, progressEvent) {
        if (progressEvent && progressEvent.lengthComputable) {
            console.log(progressEvent.loaded + ' ' + progressEvent.total);
            state.progressValue = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        } else {
            state.progressValue = null;
        }
    }
};
const uploadFileFormModule = {
    namespaced: true,
    state: initialState,
    modules: {
        users: userModule,
    },
    getters: {
        ...vuexHelpers.getters(initialState),
        ...vuexHelpers.listGetters(uploadedFileEntity),
        ...getters,
    },
    actions: { ...actions },
    mutations: {
        ...vuexHelpers.mutations(getInitialState),
        ...vuexHelpers.crudMudations,
        ...mutations,
    },
};

export default uploadFileFormModule;
