import Vue from "vue";
import dayjs from "dayjs";
export default {
  // nowMomentDate: () => Vue.moment(),
  // convertToServerDate: (date) =>
  //   date ? Vue.moment(date, "DD/MM/YYYY").toISOString(true) : null,
  // formatServerDate: (date, withTime = false) =>
  //   date
  //     ? Vue.moment(date).format(withTime ? "YYYY-MM-DD hh:mm" : "YYYY-MM-DD")
  //     : null,
  // formatIsoDate: (date) =>
  //   date ? Vue.moment(date, "YYYY-MM-DD").format("DD/MM/YYYY") : null,

  nowDayjsDate: () => dayjs(),
  convertToServerDate: (date) =>
    date ? Vue.moment(date, "DD/MM/YYYY").toISOString(true) : null,
  formatServerDate: (date, withTime = false) =>
    date
      ? dayjs(date).format(withTime ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY")
      : null,
  formatIsoDate: (date) =>
    date ? dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY") : null,
};
