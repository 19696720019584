import axios from 'axios';

const ENDPOINT_BASE_URL = `/api/uploadedFiles`;

class FilesService {

    async filesTable(options) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/table`, options);
        return res.data;

    }
    async save(options, commitFunc, commitString) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/save`, options, {

            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    commitFunc(commitString, progressEvent);
                }
            }
        });
        return res.data;

    }
    async saveShareWith(data) {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/shared-with`, data);
        return res.data;

    }
    async download(fileId) {
        const res = await axios.get(`${ENDPOINT_BASE_URL}/download/${fileId}`, {
            responseType: 'blob'
        });
        return res.data;
    }
    async delete(fileId) {
        const res = await axios.get(`${ENDPOINT_BASE_URL}/delete/${fileId}`);

        return res.data;

    }

}

export default new FilesService();