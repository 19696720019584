import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            alias: '/',
            name: 'Login',
            component: () => import('./views/Login.vue')
        },
        {
            path: '/files',
            name: 'files',
            // lazy-loaded
            component: () => import('./views/Files.vue')
        },
        {
            path: '/users',
            name: 'users',
            // lazy-loaded
            component: () => import('./views/Users.vue')
        },
        {
            path: '/logs',
            name: 'logs',
            // lazy-loaded
            component: () => import('./views/Logs.vue')
        },
        {
            path: '/notifications',
            name: 'notifications',
            // lazy-loaded
            component: () => import('./views/Notifications.vue')
        }
    ]
});
router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    }
    else {
        next();
    }
});

router.afterEach((to, from) => {
    // ...
})